import React, { useEffect, useState } from "react";
import "./App.css";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import ParticleBackground from "./components/ParticleBackground";
import TechStack from "./components/TechStack";
import Timeline from "./components/Timeline";
import { ContactProvider } from "./contexts/contactContext";
import { TimelineProvider } from "./contexts/timelineContext";
import { DarkModeProvider } from "./hooks/useTheme";

const ContentContainer: React.FC<{ id: string; children: React.ReactNode }> = ({ id, children }) => (
  <div
    id={id}
    className="backdrop-blur-sm transition-colors duration-500 ease-in-out relative z-10"
  >
    {children}
  </div>
);

const App: React.FC = () => {
  const [isScrollingEnabled, setIsScrollingEnabled] = useState(false);

  useEffect(() => {
    if (!isScrollingEnabled) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isScrollingEnabled]);

  const handleAnimationsComplete = () => {
    setIsScrollingEnabled(true);
  };

  return (
    <TimelineProvider>
      <DarkModeProvider>
        <ContactProvider>
          <div className="App">
            <Navbar />
            <div id="home" className="header-container relative">
              <ParticleBackground />
              <Header onAnimationsComplete={handleAnimationsComplete} />
            </div>
            <ContentContainer id="experience">
              <Timeline />
            </ContentContainer>
            <ContentContainer id="tech-stack">
              <TechStack />
            </ContentContainer>
            <ContentContainer id="contact">
              <Contact />
            </ContentContainer>
            <ContentContainer id="footer">
          <Footer />
            </ContentContainer>
          </div>
        </ContactProvider>
      </DarkModeProvider>
    </TimelineProvider>
  );
};

export default App;
